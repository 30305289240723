<template>
  <div class="addGood container">
    <el-form
      :model="form"
      style="min-width: 1000px"
      label-width="100px"
      :rules="rules"
      ref="ruleForm"
    >
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>基本信息</div>
      </div>
      <el-form-item label="商品排序" prop="categoryId">
        <el-input-number
          v-model="form.sort"
          step-strictly
          :step="1"
          :min="0"
          :max="999999999"
          :controls="false"
          placeholder="请输入排序值"
        ></el-input-number>
        <span style="margin-left: 10px; color: #999"
          >数字越大，排名越靠前,如果为空，默认排序方式为创建时间</span
        >
      </el-form-item>
      <el-form-item label="商品名称" prop="goods_name">
        <el-input
          v-model="form.goods_name"
          placeholder="请输入商品名称，不超过30个字符"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品编码">
        <el-input
          v-model="form.goods_code"
          placeholder="请输入商品编码"
          class="myinput"
          maxlength="20"
        ></el-input>
      </el-form-item>

      <el-form-item label="平台类目" prop="category">
        <el-cascader
          v-model="form.category"
          :options="categoryList"
          :props="{ expandTrigger: 'hover', multiple: true }"
          placeholder="选择后台分类"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="商品可见">
        <el-radio v-model="form.is_show" :label="1">是</el-radio>
        <el-radio v-model="form.is_show" :label="0">否</el-radio>
        <span style="color: #999"
          >选否时，在APP端无法搜索到该商品，常规商品列表均不展示；可在活动、扫码等配置中可显示</span
        >
      </el-form-item>
      <el-form-item label="商品类型">
        <el-radio v-model="form.goods_type" :label="1">实体商品</el-radio>
        <el-radio v-model="form.goods_type" :label="2">虚拟商品</el-radio>
      </el-form-item>
      <el-form-item label="开启售后">
        <el-radio v-model="form.is_service" :label="1">是</el-radio>
        <el-radio v-model="form.is_service" :label="0">否</el-radio>
      </el-form-item>

      <el-form-item label="商品价格" prop="price">
        <el-input-number
          v-model="form.price"
          step-strictly
          :step="0.01"
          :max="1000000000000"
          :controls="false"
        ></el-input-number>
        <span>元</span>
      </el-form-item>
      <el-form-item label="成本价" prop="cost_price">
        <el-input-number
          v-model="form.cost_price"
          step-strictly
          :step="0.01"
          :max="1000000000000"
          :controls="false"
        ></el-input-number>
        <span>元</span>
      </el-form-item>
      <el-form-item label="会员价格" prop="member_price">
        <el-input-number
          v-model="form.member_price"
          step-strictly
          :step="0.01"
          :max="1000000000"
          :controls="false"
        ></el-input-number>
        <span>元</span>
      </el-form-item>
      <el-form-item label="商品数量" prop="stock">
        <el-input-number
          v-model="form.stock"
          step-strictly
          :step="1"
          :max="1000000000"
          :controls="false"
        ></el-input-number>
        <span>件</span>
        <span style="margin-left: 10px; color: #999">拍下减库存</span>
      </el-form-item>
      <el-form-item label="商品重量">
        <el-input-number
          v-model="form.goods_weight"
          step-strictly
          :step="1"
          :max="1000000000"
          :controls="false"
        ></el-input-number>
        <span>g</span>
        <span style="margin-left: 10px; color: #999">重量可能影响物流费用</span>
      </el-form-item>
      <el-form-item label="限时售卖" prop="goods_unit">
        <el-date-picker
          v-model="form.limit_time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <span style="color: #999; margin-left: 5px"
          >超过限售时间时，商品自动下架</span
        >
      </el-form-item>
      <el-form-item label="当前状态">
        <el-radio v-model="form.state" label="1">下架</el-radio>
        <el-radio v-model="form.state" label="2">正常</el-radio>
        <el-radio v-model="form.state" label="5">暂存仓库</el-radio>
        <el-radio v-model="form.state" label="6">自动下架</el-radio>
      </el-form-item>
      <el-form-item label="虚拟出售数">
        <el-input-number
          v-model="form.sales"
          step-strictly
          :step="1"
          :max="1000000000"
          :controls="false"
        ></el-input-number>
        <span>件</span>
        <span style="margin-left: 10px; color: #999">初始的基础出售数量</span>
      </el-form-item>
      <el-form-item label="启用积分">
        <div style="display: flex; align-items: center">
          <el-switch v-model="isIntegral" @change="integralChange"> </el-switch>
          <div style="margin-left: 10px">
            设置积分抵扣金额
            <el-input-number
              size="medium"
              :disabled="!isIntegral"
              :min="0"
              v-model="form.integral_price"
              :controls="false"
            ></el-input-number>
            元
          </div>
        </div>
      </el-form-item>
      <el-form-item label="商品分享" prop="is_share">
        <el-radio v-model="form.is_share" :label="1">可分享</el-radio>
        <el-radio v-model="form.is_share" :label="0">不支持分享</el-radio>
      </el-form-item>
      <el-form-item label="轮播图片" prop="img_id_array">
        <div style="display: flex">
          <el-button
            type="text"
            style="margin-right: 20px"
            @click="historyImgShow = true"
            v-if="form.img_id_array.length <= 4"
            >选择历史图片</el-button
          >

          <el-upload
            :show-file-list="false"
            :http-request="uploadImg"
            action="img_id_array"
            v-if="form.img_id_array.length <= 4"
          >
            <el-button type="text">上传本地图片</el-button>
          </el-upload>
        </div>
        <!-- 占位图 -->
        <div class="empty-box" v-if="!form.img_id_array.length">
          <img src="../../assets/empty.png" style="width: 48px; height: 48px" />
        </div>
        <draggable
          class="imgList flex"
          style="padding: 0"
          v-model="form.img_id_array"
        >
          <div class="box" v-for="item in form.img_id_array" :key="item">
            <el-image
              :src="item"
              style="width: 150px; height: 150px; text-align: center"
              fit="fill"
            ></el-image>
            <el-upload
              :show-file-list="false"
              :http-request="
                (parms) => {
                  uploadImg(parms, item);
                }
              "
              action="img_id_array"
            >
              <div class="shadow column-center column">
                <div>
                  <i class="el-icon-search" @click.stop="showImg(item)"></i>

                  <i class="el-icon-delete" @click.stop="delImg(item, 1)"></i>
                </div>
              </div>
            </el-upload>
          </div>
        </draggable>
        <div style="color: #999">
          商品详情页轮播图片，大小在5MB以内，支持png,jpeg,gif格式，最多可上传5张
        </div>
      </el-form-item>
      <el-form-item label="商品视频">
        <div style="display: flex">
          <el-button type="text" style="margin-right: 20px" @click="checkVideo"
            >选择历史视频</el-button
          >
          <el-button type="text" style="margin-right: 20px" @click="uploadVideo"
            >上传本地视频</el-button
          >
        </div>
        <div v-if="chooseVideo.video_url_str" class="chooseVideoBox">
          <i class="el-icon-circle-close closed" @click="deleteVideo"></i>
          <video
            :src="chooseVideo.video_url_str"
            style="width: 150px;height: 120px;"
            :poster="chooseVideo.mian_url_str"
            controls
            @click="openPreview"
          ></video>
        </div>
        <div style="color: #999">
          视频大小不超过 30 MB，建议时长五分钟以内， 建议宽高比9:16，
          支持的视频文件类型包括：.mp4
        </div>
      </el-form-item>
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>商品参数</div>
        <div
          style="color: #aaaaaa;font-size: 14px;font-weight:400;margin-left:10px;"
        >
          商品参数只在后台展示，非必填
        </div>
      </div>
      <div style="display:flex;align-items: center;flex-wrap: wrap;">
        <el-form-item
          :label="item.name"
          v-for="(item, index) in form.parameter_content.slice(0, 4)"
          :key="index"
          :prop="'parameter_content.' + index + '.value'"
          :rules="rules.parameter_content2"
        >
          <el-input v-model="item.value" style="padding-right:56px;"></el-input>
        </el-form-item>
      </div>
      <div style="display:flex;align-items: center;flex-wrap: wrap;">
        <div
          v-for="(item, index) in form.parameter_content1"
          :key="index"
          style="display: flex;align-items: center;align-items: flex-start"
        >
          <el-form-item
            label-width="0"
            :prop="'parameter_content1.' + index + '.name'"
            :rules="rules.parameter_content1"
          >
            <el-input
              v-model="item.name"
              style="margin-right:10px;width: 90px;margin-bottom: 18px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            label-width="0"
            :prop="'parameter_content1.' + index + '.value'"
            :rules="rules.parameter_content2"
          >
            <el-input
              v-model="item.value"
              style="margin-bottom: 18px;"
            ></el-input>
          </el-form-item>
          <el-button
            type="text"
            @click="delThisParameterContent(index)"
            style="margin-right:30px;"
            >删除</el-button
          >
        </div>
      </div>
      <el-button
        type="text"
        style="margin-left:100px"
        v-if="form.parameter_content1.length <= 16"
        @click="addParameterContent"
        >添加自定义字段</el-button
      >
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>规格设置</div>
      </div>
      <el-form-item label="启用商品规格">
        <el-switch v-model="form.open_spec"> </el-switch>
        <el-button
          type="primary"
          style="margin-left: 30px"
          :disabled="!form.open_spec"
          @click="addSpec"
          >添加规格</el-button
        >
      </el-form-item>
      <div style="color: #aaaaaa; margin-bottom: 20px; margin-left: 3px">
        启用商品规格后，商品的价格及库存以商品规格为准，系统最多支持设置2个规格项。
      </div>
      <div class="new_box" v-if="form.open_spec && form.spec_list.length !== 0">
        <template v-if="form.spec_list[0]">
          <div class="gg_name_box">
            <div class="gg_name_item">
              <div class="name" style="margin-right: 5px">规格名称</div>
              <div>
                <el-form-item
                  label-width="0"
                  :prop="form.spec_list[0].spec_name"
                  :rules="rules.name"
                  style="margin-bottom: 0"
                >
                  <el-input
                    v-model="form.spec_list[0].spec_name"
                    placeholder="填输入规格名称"
                  />
                </el-form-item>
              </div>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close"
                style="font-size: 18px"
                @click="delSpec(1)"
              ></i>
            </div>
          </div>
          <div class="gg_item" style="padding: 0 10px; display: flex">
            <div
              style="
                margin-right: 5px;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 32px;
              "
            >
              规格值
            </div>
            <div class="box_content flex wrap">
              <div
                class="spec_box flex align-center"
                v-for="(item, index) in form.spec_list[0].value"
                :key="item.spec_value_id"
                style="margin-bottom: 10px"
              >
                <el-form-item
                  label=""
                  label-width="0"
                  :prop="'spec_list[0].value.' + index + '.spec_value_name'"
                  :rules="rules.spec_value_name1"
                  style="margin-bottom: 0"
                >
                  <el-input
                    v-model="item.spec_value_name"
                    placeholder="填写规格值"
                    maxlength="64"
                    @input="inputlop"
                  >
                    <i
                      slot="suffix"
                      class="el-input__icon el-icon-circle-close"
                      @click="delSpecChild(1, item.spec_value_id)"
                      style="
                        width: 16px;
                        height: 24px;
                        font-size: 16px;
                        /* position: absolute;
                        top: 6px;
                        left: -6px; */
                        background-color: none;
                      "
                    ></i
                  ></el-input>
                </el-form-item>
                <i></i>
                <el-upload
                  :show-file-list="false"
                  :http-request="
                    (parms) => {
                      uploadImg(parms, item);
                    }
                  "
                  action
                >
                  <i class="el-icon-picture-outline" v-show="!item.picture"></i>
                  <img :src="item.picture" alt="" v-show="item.picture" />
                </el-upload>
              </div>
              <div
                class="spec_add"
                v-show="form.spec_list[0].value.length < 10"
              >
                <span @click="addSpecChild(1)">添加规格值</span>
              </div>
            </div>
          </div>
        </template>
        <template v-if="form.spec_list[1]">
          <div class="gg_name_box">
            <div class="gg_name_item">
              <div class="name" style="margin-right: 5px">规格名称</div>
              <div>
                <el-form-item
                  label-width="0"
                  :prop="form.spec_list[1].spec_name"
                  :rules="rules.name"
                  style="margin-bottom: 0"
                >
                  <el-input
                    v-model="form.spec_list[1].spec_name"
                    placeholder="填输入规格名称"
                  />
                </el-form-item>
              </div>
              <i
                slot="suffix"
                class="el-input__icon el-icon-circle-close"
                style="font-size: 18px"
                @click="delSpec(2)"
              ></i>
            </div>
          </div>
          <div class="gg_item" style="padding: 0 10px; display: flex">
            <div
              style="
                margin-right: 5px;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 32px;
              "
            >
              规格值
            </div>
            <div class="box_content flex wrap">
              <div
                class="spec_box flex align-center"
                v-for="(item, index) in form.spec_list[1].value"
                :key="item.spec_value_id"
                style="margin-bottom: 10px"
              >
                <el-form-item
                  label=""
                  label-width="0"
                  :prop="'spec_list[1].value.' + index + '.spec_value_name'"
                  :rules="rules.spec_value_name1"
                  style="margin-bottom: 0"
                >
                  <el-input
                    v-model="item.spec_value_name"
                    placeholder="填写规格值"
                    maxlength="64"
                    @input="inputlop"
                  >
                    <i
                      slot="suffix"
                      class="el-input__icon el-icon-circle-close"
                      @click="delSpecChild(2, item.spec_value_id)"
                      style="
                        width: 16px;
                        height: 24px;
                        font-size: 16px;
                        /* position: absolute;
                        top: 6px;
                        left: -6px; */
                        background-color: none;
                      "
                    ></i
                  ></el-input>
                </el-form-item>
                <i></i>
              </div>
              <div
                class="spec_add"
                v-show="form.spec_list[1].value.length < 10"
              >
                <span @click="addSpecChild(2)">添加规格值</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        class="lineH-box"
        v-if="form.open_spec && form.spec_list.length !== 0"
      >
        <div class="lineH"></div>
        <div>设置规格</div>
      </div>
      <div
        class="new_table table-box"
        v-if="form.open_spec && form.spec_list.length !== 0"
      >
        <el-table
          ref="multipleTable"
          tooltip-effect="dark"
          :data="form.sku_list"
          :header-cell-style="{ background: '#F5F5F5' }"
          :span-method="objectSpanMethod"
          border
          style="max-width: 100%; margin-top: 20px"
        >
          <el-table-column
            v-if="form.spec_list[0] && form.spec_list[0].spec_name"
            prop="sku_1"
            :label="form.spec_list[0].spec_name"
          ></el-table-column
          ><el-table-column
            v-if="form.spec_list[1] && form.spec_list[1].spec_name"
            prop="sku_2"
            :label="form.spec_list[1].spec_name"
          ></el-table-column
          ><el-table-column
            label="价格（元）"
            min-width="140px"
            :render-header="addRedStar"
          >
            <template slot-scope="scope">
              <el-form-item
                :key="scope.row.key"
                label-width="0"
                :prop="'sku_list.' + scope.$index + '.price'"
                :rules="rules.price"
              >
                <el-input-number
                  v-model="scope.row.price"
                  step-strictly
                  :step="0.01"
                  :max="9999999999"
                  :controls="false"
                  style="width: 120px"
                  placeholder="填写销售价"
                  :rules="rules.price"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="成本价" min-width="140px">
            <template slot-scope="scope">
              <el-form-item label-width="0" :key="scope.row.key">
                <el-input-number
                  v-model="scope.row.cost_price"
                  step-strictly
                  :step="0.01"
                  style="width: 120px"
                  :max="9999999999"
                  :controls="false"
                  placeholder="填写成本价"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="member_price" label="会员价" min-width="140px">
            <template slot-scope="scope">
              <el-form-item label-width="0" :key="scope.row.key">
                <el-input-number
                  v-model="scope.row.member_price"
                  step-strictly
                  :step="0.01"
                  style="width: 120px"
                  :max="9999999999"
                  :controls="false"
                  placeholder="填写会员价"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="stock"
            label="数量（件）"
            min-width="140px"
            :render-header="addRedStar"
          >
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :key="scope.row.key"
                :prop="'sku_list.' + scope.$index + '.stock'"
                :rules="rules.stock"
              >
                <el-input-number
                  v-model="scope.row.stock"
                  step-strictly
                  :step="0.01"
                  style="width: 120px"
                  :max="9999999999"
                  :controls="false"
                  placeholder="填写库存"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="goods_code" label="商品编码" min-width="140px">
            <template slot-scope="scope">
              <el-form-item label-width="0" :key="scope.row.key">
                <el-input
                  v-model="scope.row.goods_code"
                  placeholder="填写商品编码"
                  class="myinput"
                  maxlength="20"
                  style="width: 120px"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="weight"
            label="商品重量（g）"
            min-width="140px"
          >
            <template slot-scope="scope">
              <el-form-item label-width="0" :key="scope.row.key">
                <el-input-number
                  v-model="scope.row.weight"
                  step-strictly
                  :step="0.01"
                  style="width: 120px"
                  :max="9999999999"
                  :controls="false"
                  placeholder="填写重量"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="商品备注" min-width="220px">
            <template slot-scope="scope">
              <el-form-item label-width="0" :key="scope.row.key">
                <el-input
                  v-model="scope.row.remark"
                  type="textarea"
                  placeholder="填写商品备注"
                  class="myinput"
                  maxlength="200"
                  style="max-width: 200px"
                  autosize
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div v-if="form.open_spec">
        <template v-if="form.spec_list[0] && form.spec_list[0].spec_name">
          <div class="spec_title flex align-center">
            <div class="name">规格:{{ form.spec_list[0].spec_name }}</div>
            <el-button type="text" @click="delSpec(1)">删除规格</el-button
            ><span class="gray"
              >第1个规格的规格值可设置图片，尽量上传方形图片，大小在5MB以内，支持png,jpeg,gif格式，最多传1张（非必传）</span
            >
          </div>

          <div class="box_content flex wrap">
            <div
              class="spec_box flex align-center"
              v-for="(item, index) in form.spec_list[0].value"
              :key="item.spec_value_id"
            >
              <el-form-item
                label=""
                label-width="0"
                :prop="'spec_list[0].value.' + index + '.spec_value_name'"
                :rules="rules.spec_value_name1"
                class="flex align-center"
              >
                <el-input
                  v-model.trim="item.spec_value_name"
                  placeholder="填写规格值"
                  maxlength="64"
                ></el-input>
              </el-form-item>
              <i
                class="el-icon-delete"
                @click="delSpecChild(1, item.spec_value_id)"
              ></i>
              <el-upload
                :show-file-list="false"
                :http-request="
                  (parms) => {
                    uploadImg(parms, item);
                  }
                "
                action
              >
                <i class="el-icon-picture-outline" v-show="!item.picture"></i>
                <img :src="item.picture" alt="" v-show="item.picture" />
              </el-upload>
            </div>
            <div class="spec_add">
              <i
                class="el-icon-circle-plus-outline"
                @click="addSpecChild(1)"
                v-show="form.spec_list[0].value.length < 20"
              ></i
              ><span @click="addSpecChild(1)">添加子规格</span>
            </div>
          </div>
        </template>
        <template v-if="form.spec_list[1] && form.spec_list[1].spec_name">
          <div class="spec_title flex align-center">
            <div class="name">规格:{{ form.spec_list[1].spec_name }}</div>
            <el-button type="text" @click="delSpec(2)">删除规格</el-button>
          </div>

          <div class="box_content flex wrap">
            <div
              class="spec_box flex align-center"
              v-for="(item, index) in form.spec_list[1].value"
              :key="item.spec_value_id"
            >
              <el-form-item
                label=""
                label-width="0"
                :prop="'spec_list[1].value.' + index + '.spec_value_name'"
                :rules="rules.spec_value_name1"
                class="flex align-center"
              >
                <el-input
                  v-model="item.spec_value_name"
                  placeholder="填写规格值"
                  maxlength="64"
                ></el-input>
              </el-form-item>
              <i
                class="el-icon-delete"
                @click="delSpecChild(2, item.spec_value_id)"
              ></i>
            </div>
            <div class="spec_add">
              <i
                class="el-icon-circle-plus-outline"
                @click="addSpecChild(2)"
                v-show="form.spec_list[1].value.length < 20"
              ></i
              ><span @click="addSpecChild(2)">添加子规格</span>
            </div>
          </div>
        </template>
        <div
          class="sku_box flex"
          v-for="(item, index) in form.sku_list"
          :key="index"
        >
          <div style="width: auto">
            <el-form-item label="" label-width="0">
              <div class="title">{{ item.sku_name }}：</div>
            </el-form-item>
          </div>
          <div class="sku-box-detail wrap">
            <el-form-item
              label="价格（元）"
              :prop="'sku_list.' + index + '.price'"
              :rules="rules.price"
            >
              <el-input-number
                v-model="item.price"
                step-strictly
                :step="0.01"
                :max="1000000000000"
                :controls="false"
                placeholder="填写销售价"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="成本价">
              <el-input-number
                v-model="item.cost_price"
                step-strictly
                :step="0.01"
                :max="1000000000000"
                :controls="false"
                placeholder="填写成本价"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="会员价">
              <el-input-number
                v-model="item.member_price"
                step-strictly
                :step="0.01"
                :max="1000000000000"
                :controls="false"
                placeholder="填写会员价"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="数量"
              :prop="'sku_list.' + index + '.stock'"
              :rules="rules.stock"
            >
              <el-input-number
                v-model="item.stock"
                step-strictly
                :step="1"
                :max="1000000000000"
                :controls="false"
                placeholder="填写库存"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="商品编码">
              <el-input
                v-model="item.goods_code"
                placeholder="填写商品编码"
                class="myinput"
                maxlength="20"
                style="width: 120px"
              ></el-input>
            </el-form-item>
            <el-form-item label="重量（g）">
              <el-input-number
                v-model="item.weight"
                step-strictly
                :max="1000000000000"
                :controls="false"
                placeholder="填写重量"
              ></el-input-number>
            </el-form-item>
          </div>
        </div>
      </div> -->

      <div class="lineH-box">
        <div class="lineH"></div>
        <div>商品详情</div>
      </div>
      <el-form-item label="商品详情">
        <el-upload
          :show-file-list="false"
          :http-request="uploadImg"
          action="description"
          v-show="form.description.length < 20"
        >
          <el-button type="text">上传本地图片</el-button>
        </el-upload>
        <!-- 占位图 -->
        <div class="empty-box" v-if="!form.description.length">
          <img src="../../assets/empty.png" style="width: 48px; height: 48px" />
        </div>
        <draggable
          class="imgList flex wrap"
          style="padding: 0"
          v-model="form.description"
        >
          <div class="box" v-for="item in form.description" :key="item">
            <el-image
              :src="item"
              style="width: 150px; height: 150px; text-align: center"
              fit="fill"
            ></el-image>
            <el-upload
              :show-file-list="false"
              :http-request="
                (parms) => {
                  uploadImg(parms, item);
                }
              "
              action="description"
            >
              <div class="shadow column-center column">
                <div>
                  <i class="el-icon-search" @click.stop="showImg(item)"></i>

                  <i class="el-icon-delete" @click.stop="delImg(item, 2)"></i>
                </div>
              </div>
            </el-upload>
          </div>
        </draggable>
        <span style="color: #999; margin-left: 10px"
          >商品详情图片，大小在5MB以内，支持png,jpeg,gif格式，最多可上传20张</span
        >
      </el-form-item>
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>推荐商品</div>
      </div>
      <div style="color: #aaaaaa; margin-bottom: 20px; margin-left: 3px">
        推荐商品建议设置商品的个数为3的倍数，例如3、6、9，当设置的有效商品不足3个时，商详页将不展示此模块
      </div>
      <div style="display: flex;align-items: center;">
        <draggable
          class="flex wrap"
          v-model="chooseGoodsData"
          style="padding: 0"
        >
          <div
            class="recommendBox"
            v-for="(i, index) in chooseGoodsData"
            :key="index"
          >
            <div class="itemBox">
              <img :src="i.picture_str" alt="" />
              <i class="el-icon-error" @click.stop="delThisGoods(index)"></i>
            </div>
            <div class="title">{{ i.goods_name }}</div>
          </div>
        </draggable>
        <el-button
          plain
          type="primary"
          style="margin: 0 10px"
          v-if="chooseGoodsData.length < 10"
          @click="openGoods"
          >添加商品</el-button
        >
      </div>
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>配送设置</div>
      </div>
      <!-- <el-form-item label="运费设置"
                    prop="shipping_fee_id">
        <el-radio v-model="form.shipping_fee_type"
                  label="3">仅自提</el-radio>
        <el-radio v-model="form.shipping_fee_type"
                  label="1">统一运费</el-radio>
        <el-input-number v-model="form.shipping_fee"
                         step-strictly
                         :step="0.01"
                         :max="1000000000"
                         :controls="false"
                         style="width: 100px"></el-input-number><span style="margin-right: 5px">元</span>
        <el-radio v-model="form.shipping_fee_type"
                  style="margin-left: 15px"
                  label="2">运费模板</el-radio>
        <el-select @click.native="getShippingFee"
                   v-model="form.shipping_fee_id"
                   placeholder="请选择运费模板">
          <el-option v-for="(item, index) in feeList"
                     :label="item.shipping_name"
                     :value="item.shipping_id"
                     :key="index"></el-option>
        </el-select>
        <el-button type="primary"
                   style="margin: 0 10px"
                   @click="toAddFreight">新建运费模板</el-button>
        <span style="margin-left: 10px; color: #999">新增模板后再次点击下拉选择可刷新</span>
      </el-form-item> -->

      <el-form-item label="配送方式">
        <el-radio v-model="form.psfs_id" label="4">快递</el-radio>
        <el-radio v-model="form.psfs_id" label="3">仅自提</el-radio
        ><span style="margin-left: 10px; color: #999"
          >自提方式需在地址设置中开启后才可生效</span
        >
      </el-form-item>
      <el-form-item
        label="运费设置"
        prop="shipping_fee_type"
        v-if="form.psfs_id !== '3'"
      >
        <div>
          <el-radio v-model="form.shipping_fee_type" label="1"
            >统一运费</el-radio
          >
          <el-input-number
            v-model="form.shipping_fee"
            step-strictly
            :step="0.01"
            :max="1000000000"
            :controls="false"
            style="width: 100px"
          ></el-input-number
          ><span style="margin-right: 5px">元</span>
        </div>
        <div style="margin-top: 15px">
          <el-radio v-model="form.shipping_fee_type" label="2"
            >运费模板</el-radio
          >
          <el-select
            @click.native="getShippingFee"
            v-model="form.shipping_fee_id"
            placeholder="请选择运费模板"
          >
            <el-option
              v-for="(item, index) in feeList"
              :label="item.shipping_name"
              :value="item.shipping_id"
              :key="index"
            ></el-option>
          </el-select>
          <el-button
            plain
            type="primary"
            style="margin: 0 10px"
            @click="toAddFreight"
            >新建运费模板</el-button
          >
          <span style="margin-left: 10px; color: #999"
            >新增模板后再次点击下拉选择可刷新</span
          >
        </div>
      </el-form-item>
    </el-form>

    <div class="submit flex center align-center">
      <el-button @click="cancel" size="medium">取消</el-button>
      <el-button type="primary" @click="submit" size="medium">提交</el-button>
    </div>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false;
        }
      "
      :url-list="imgList"
    />
    <!-- 历史图片 -->
    <HistoryImg
      v-if="historyImgShow"
      @chooseImg="chooseImg"
      @close="historyImgShow = false"
    ></HistoryImg>

    <!-- 添加规格 -->
    <el-dialog title="添加规格" :visible.sync="specShow" width="400px">
      <div>
        <el-form
          :model="specForm"
          label-width="100px"
          :rules="rules"
          ref="specForm"
        >
          <el-form-item label="" prop="spec_name" label-width="0">
            <el-input
              v-model="specForm.spec_name"
              placeholder="请输入规格名，如：颜色、尺寸"
              maxlength="32"
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="specShow = false">取 消</el-button>
        <el-button type="primary" @click="specConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="历史视频" :visible.sync="historyVideoShow" width="700px">
      <el-input
        v-model="thekeywords"
        placeholder="搜索视频名"
        @input="getVideo"
      ></el-input>
      <div class="videoBox">
        <div class="videoItem" v-for="(item, index) in videolist" :key="index">
          <div
            :style="
              `border-radius: 3px;width:100%;height:100%;background: url('${item.mian_url_str}');background-size: cover;`
            "
            @click="checkImg(index)"
          >
            <img
              src="../../assets/checkimg.png"
              class="checkimg"
              v-if="item.check"
            />
            <div class="timeBox">
              {{ formatTime(item.long_time) }}
            </div>
          </div>
          <div class="name">
            {{ item.video_title }}
          </div>
        </div>
        <div v-if="!videolist.length">暂无视频</div>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="margin: 30px 0 40px 0"
      ></el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="historyVideoShow = false">取 消</el-button>
        <el-button type="primary" @click="historyVideoConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 商品弹框 -->
    <el-dialog title="选择商品" :visible.sync="chooseGoodsShow" width="800px">
      <div class="goods-content">
        <div class="searchBox">
          <el-input placeholder="商品关键字" v-model="goods_name" clearable>
          </el-input>
          <el-button
            type="primary"
            style="margin-bottom: 10px"
            @click="getGoodsList"
            >查询</el-button
          >
        </div>
        <div class="table-box" style="border: none">
          <el-table
            :data="goodsData"
            tooltip-effect="dark"
            style="width: 100%"
            height="400"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="全部商品">
              <template slot-scope="scope">
                <div class="item">
                  <img :src="scope.row.picture_str" />
                  <div>
                    {{ scope.row.goods_name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="销量" prop="real_sales"> </el-table-column>
            <el-table-column label="库存" prop="stock"> </el-table-column>
          </el-table>
          <div class="table-text">已选：{{ goodsSelection.length }} 件商品</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeChooseGoodsShow">取 消</el-button>
        <el-button type="primary" @click="confirmGoods">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="上传视频" :visible.sync="uploadVideoShow" width="600px">
      <el-form :model="videoInfo" :rules="videoRules" ref="videoRuleForm">
        <el-form-item label="本地视频" prop="videoUrl">
          <el-upload
            :show-file-list="false"
            action="/"
            :http-request="customUpload"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
          >
            <div
              v-if="!videoInfo.videoUrlStr"
              style="width:150px;height:150px;background: #f7f8fa;line-height: 150px;"
            >
              <i slot="default" class="el-icon-plus"></i>
            </div>
            <video
              v-else
              style="width:150px;height:150px;"
              :src="videoInfo.videoUrlStr"
            />
          </el-upload>
          <div style="color: #999;padding-left:80px;box-sizing:border-box;">
            视频大小不超过30MB,建议时长五分钟以内,建议宽高比9:16,支持的视频文件类型包括,.mp4
          </div>
        </el-form-item>
        <el-form-item label="视频名称" prop="videoTitle">
          <el-input
            v-model="videoInfo.videoTitle"
            placeholder="20个字以内"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="视频封面">
          <el-upload
            :show-file-list="false"
            action="videoPic"
            :http-request="uploadImg"
            v-if="!videoInfo.mianUrl.path"
          >
            <div
              style="width:150px;height:150px;background: #f7f8fa;line-height: 150px;"
            >
              <i slot="default" class="el-icon-plus"></i>
            </div>
          </el-upload>
          <el-upload
            v-if="videoInfo.mianUrl.path"
            :show-file-list="false"
            :http-request="
              (parms) => {
                uploadImg(parms, videoInfo.mianUrl.path);
              }
            "
            action="videoPic"
            class="imgList"
          >
            <div class="box">
              <el-image
                style="width: 150px; height: 150px; text-align: center"
                fit="fill"
                v-if="videoInfo.mianUrl.path"
                :src="videoInfo.mianUrl.path"
              ></el-image>
              <div class="shadow column-center column">
                <div>
                  <i
                    class="el-icon-search"
                    @click.stop="showImg(videoInfo.mianUrl.path)"
                  ></i>

                  <i
                    class="el-icon-delete"
                    @click.stop="delImg(videoInfo.mianUrl, 3)"
                  ></i>
                </div>
              </div>
            </div>
          </el-upload>
          <div style="color: #999;padding-left:80px;box-sizing:border-box;">
            建议尺寸：900*1600px,支持.jpg,.gif,.png,.bmp格式，大小不超过10MB,如果不添加封面，系统会默认截取视频的第一个画面作为封面。
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadVideoShow = false">取 消</el-button>
        <el-button type="primary" @click="uploadVideoCerten">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 模态框 -->
    <div
      v-if="isPreviewVisible"
      class="preview-modal"
      @click.self="closePreview"
      style="z-index: 999;"
    >
      <video
        ref="previewPlayer"
        width="800"
        height="450"
        :poster="chooseVideo.mian_url_str"
        controls
        @click.stop
      >
        <source :src="chooseVideo.video_url_str" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
import commonUtil from "../../utils/commonUtil.js";
import HistoryImg from "./HistoryImg";
import { uploadImg, getImgUrl } from "../../service/common";
import draggable from "vuedraggable";
import discounts from "../../api/discounts";
export default {
  name: "addGood",
  data() {
    //validateName
    let validateName = (rule, value, callback) => {
      // if (rule.field === "") {
      //   callback(new Error("请填写规格名称"));
      // } else {
      //   callback();
      // }
      const regEn = /[`!@#$%^&]/;
      const regCn = /[~！@#￥%……&]/;
      const kong = /\s/;
      const regAll = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/; //数字、字母和中文组成，不能包含特殊符号和空格。
      if (
        regEn.test(rule.field) ||
        regCn.test(rule.field) ||
        kong.test(rule.field)
      ) {
        callback(new Error("请检查输入是否正确，不能输入空格及特殊字符"));
      }
      if (rule.field === "") {
        callback(new Error("请填写规格名称"));
      } else {
        callback();
      }
    };
    //规格名验证
    let validateSpecName = (rule, value, callback) => {
      // if (value === "") {
      //   callback(new Error("请填写规格名"));
      // } else {
      //   let index = this.form.spec_list.findIndex((ele) => {
      //     return ele.spec_name == value;
      //   });
      //   if (index != -1) {
      //     callback(new Error("规格名重复"));
      //   }
      //   callback();
      // }
      if (value === "") {
        callback(new Error("请填写规格名"));
      } else {
        const regEn = /[`!@#$%^&]/;
        const regCn = /[~！@#￥%……&]/;
        const kong = /\s/;
        const regAll = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/; //数字、字母和中文组成，不能包含特殊符号和空格。
        if (regEn.test(value) || regCn.test(value) || kong.test(value)) {
          callback(new Error("请检查输入是否正确，不能输入空格及特殊字符"));
        }
        let index = this.form.spec_list.findIndex((ele) => {
          return ele.spec_name == value;
        });
        if (index != -1) {
          callback(new Error("规格名重复"));
        }
        callback();
      }
    };

    //规格子名验证
    let validateSpecValueName = (rule, value, callback, list) => {
      // if (value === "") {
      //   this.setSkuList();
      //   callback(new Error("请填写规格名"));
      //   return;
      // } else {
      //   let arr = list.filter((ele) => {
      //     return ele.spec_value_name == value;
      //   });
      //   if (arr.length > 1) {
      //     callback(new Error("子规格名重复"));
      //     return;
      //   }
      //   this.setSkuList();
      //   callback();
      // }
      if (value === "") {
        this.setSkuList();
        callback(new Error("请填写规格名"));
        return;
      } else {
        const regEn = /[`!@#$%^&]/;
        const regCn = /[~！@#￥%……&]/;
        const kong = /\s/;
        const regAll = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/; //数字、字母和中文组成，不能包含特殊符号和空格。
        if (regEn.test(value) || regCn.test(value) || kong.test(value)) {
          callback(new Error("请检查输入是否正确，不能输入空格及特殊字符"));
        }
        let arr = list.filter((ele) => {
          return ele.spec_value_name == value;
        });
        if (arr.length > 1) {
          callback(new Error("子规格名重复"));
          return;
        }
        this.setSkuList();
        callback();
      }
    };
    // pfsf
    let pfsfvalidateName = (rule, value, callback) => {
      console.log("value", value);
      if (this.form.psfs_id == 4) {
        if (
          !(
            this.form.shipping_fee_type == 1 || this.form.shipping_fee_type == 2
          )
        ) {
          console.log("0123", this.form.shipping_fee_type);
          callback(new Error("请设置运费"));
        } else {
          console.log("3210", this.form.shipping_fee_type);

          callback();
        }
      }
    };
    return {
      goods_id: this.$route.query.goods_id,
      form: {
        sort: undefined,
        goods_name: "",
        goods_code: "",
        category: [],
        goods_type: 1,
        goods_weight: undefined,
        img_id_array: [],
        is_share: 1,
        price: undefined,
        cost_price: undefined,
        member_price: undefined,
        stock: undefined,
        limit_time: [],
        state: "2",
        sales: undefined,
        psfs_id: "4",
        shipping_fee_type: "1",
        shipping_fee: undefined,
        shipping_fee_id: "",
        description: [],
        open_spec: false,
        spec_list: [], //规格列表
        sku_list: [], //sku列表
        is_show: 1,
        is_service: 0,
        integral_price: 0,
        parameter_content: [
          { name: "品牌", value: "" },
          { name: "品名", value: "" },
          { name: "规格", value: "" },
          { name: "产地", value: "" },
        ],
        parameter_content1: [],
      },
      goods_name: "", //搜索商品名称
      goodsSelection: "", //已选
      goodsData: [],
      chooseGoodsData: [],
      chooseGoodsShow: false,
      isIntegral: false,
      specForm: {
        spec_name: "", //规则名
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      categoryList: [],
      open_spec: true, //是否启用规格
      historyImgShow: false, //历史图片弹窗
      historyImgShow: false, //历史图片弹窗
      total: 0,
      page: 1,
      pageNumber: 12,
      videolist: [], //视频列表
      historyVideoShow: false, //历史视频弹窗
      isPreviewVisible: false,
      chooseVideo: {}, //符合条件的视频
      thekeywords: "", //搜索视频的关键字
      uploadVideoShow: false, //上传视频
      videoInfo: {
        videoUrl: "",
        videoUrlStr: "",
        videoTitle: "",
        mianUrl: {},
        longTime: "",
      },
      videoRules: {
        videoUrl: [
          { required: true, message: "请选择本地视频", trigger: "blur" },
        ],
        videoTitle: [
          { required: true, message: "请输入视频名称", trigger: "blur" },
        ],
      },
      coverImg: "", //绘制的封面
      showViewer: false, //图片预览
      imgList: [], //预览图片列表
      feeList: [], //运费模板列表
      rules: {
        parameter_content1: [
          { required: true, message: "请输入商品参数名", trigger: "blur" },
        ],
        parameter_content2: [
          { required: true, message: "请输入商品参数值", trigger: "blur" },
        ],
        goods_name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        category: [
          { required: true, message: "请选择平台类目", trigger: "blur" },
        ],
        img_id_array: [{ required: true, message: "请选择轮播图片" }],
        is_share: [{ required: true, message: "请选择商品是否可分享" }],
        price: [{ required: true, message: "请输入商品价格", trigger: "blur" }],
        stock: [{ required: true, message: "请输入商品库存", trigger: "blur" }],
        spec_name: [{ validator: validateSpecName, trigger: "blur" }],
        name: [{ validator: validateName, trigger: "blur" }],
        spec_value_name1: [
          {
            validator: (rule, value, callback) => {
              validateSpecValueName(
                rule,
                value,
                callback,
                this.form.spec_list[0].value
              );
            },
            trigger: "blur",
          },
        ],
        spec_value_name2: [
          {
            validator: (rule, value, callback) => {
              validateSpecValueName(
                rule,
                value,
                callback,
                this.form.spec_list[1].value
              );
            },
            trigger: "blur",
          },
        ],
        shipping_fee_type: [
          { validator: pfsfvalidateName, trigger: "change", required: true },
        ],
      },
      specShow: false,
    };
  },
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
    HistoryImg,
    draggable,
  },
  // watch: {
  //   //判断能否开启规格
  //   "form.sku_list": {
  //     handler(newValue, oldValue) {
  //       if (newValue.length == 0) {
  //         this.open_spec = true;
  //         this.form.open_spec = false;
  //       } else {
  //         this.open_spec = false;
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {
    this.getGoodsInfo();
    this.getCategoryList();
    this.getShippingFee();
  },
  methods: {
    getGoodsInfo() {
      let data = {
        goods_id: this.goods_id,
      };
      this.axios.post("/store/Shopgoods/getGoodsInfo", data).then((res) => {
        let info = res.data.info;
        console.log("222", info);
        let form = {
          sort: info.sort,
          goods_name: info.goods_name,
          goods_code: info.goods_code,
          goods_type: info.goods_type,
          goods_weight: info.goods_weight || undefined,
          img_id_array: info.img_id_array_str || [],
          price: info.price || undefined,
          cost_price: info.cost_price || undefined,
          member_price: info.member_price || undefined,
          stock: info.stock || undefined,
          limit_time: [],
          state: info.state.toString(),
          sales: info.sales || undefined,
          shipping_fee_type: info.shipping_fee_type.toString(),
          psfs_id:
            info.shipping_fee_type.toString() == 1 ||
            info.shipping_fee_type.toString() == 2
              ? "4"
              : "3",
          shipping_fee: info.shipping_fee,
          shipping_fee_id: info.shipping_fee_id || "",
          description: info.description_str || [],
          open_spec: info.open_spec == 1 ? true : false,
          spec_list: info.spec_value_items || [],
          sku_list: info.sku_list || [],
          is_show: info.is_show,
          is_service: info.is_service,
          integral_price: info.integral_price,
          is_share: info.is_share,
          parameter_content: info.parameter_content.slice(0, 4),
          parameter_content1:
            info.parameter_content.length > 4
              ? info.parameter_content.slice(
                  4,
                  info.parameter_content.length + 1
                )
              : [],
        };
        this.chooseGoodsData = info.recommend_list.map((el) => {
          el.picture_str = el.picture;
          return el;
        });

        this.chooseVideo = {
          mian_url: info.mian_url,
          mian_url_str: info.mian_url_str,
          video_url: info.video_url,
          video_url_str: info.video_url_str,
        };
        let category_id_list_arr = info.category_id_list.map((el) => {
          return [el.category_id_1, el.category_id_2];
        });
        console.log("category_id_list_arr", category_id_list_arr);
        let category = category_id_list_arr;
        form.category = category;
        if (info.limit_time_start) {
          form.limit_time = [
            info.limit_time_start * 1000,
            info.limit_time_end * 1000,
          ];
        }
        if (form.member_price == 0) {
          form.member_price = undefined;
        }
        if (form.cost_price == 0) {
          form.cost_price = undefined;
        }
        form.sku_list.forEach((ele) => {
          if (ele.member_price == 0) {
            ele.member_price = undefined;
          }
          if (ele.cost_price == 0) {
            ele.cost_price = undefined;
          }
          if (ele.weight == 0) {
            ele.weight = undefined;
          }
        });
        this.form = form;
        this.isIntegral = form.integral_price == 0 ? false : true;
        this.setSkuList();
      });
    },
    // 给表头加必填符号*
    addRedStar(h, { column }) {
      return [
        h("span", { style: "color: red" }, "*"),
        h("span", " " + column.label),
      ];
    },
    //平台分类列表
    getCategoryList() {
      this.axios.post("/store/Shopgoods/getCategoryList").then((res) => {
        let list = res.data.list;
        let arr = [];
        list.forEach((ele) => {
          if (ele.child.length == 0) {
            arr.push({
              value: ele.category_id,
              label: ele.category_name,
            });
          } else {
            let childArr = [];
            ele.child.forEach((e) => {
              childArr.push({
                value: e.category_id,
                label: e.category_name,
              });
            });
            arr.push({
              value: ele.category_id,
              label: ele.category_name,
              children: childArr,
            });
          }
        });
        this.categoryList = arr;
      });
    },
    // 获取商品列表
    getGoodsList() {
      let params = {
        goods_name: this.goods_name,
        pageNumber: 1000,
      };
      discounts.shopGoodsLists(params).then((res) => {
        let { code, data } = res;
        if (code === 0) {
          this.goodsData = data.list;
          // 过滤掉列表已经有的数据
          if (this.chooseGoodsData) {
            this.goodsData = this.goodsData.filter((item) => {
              return !this.chooseGoodsData.find((prop) => {
                return prop.goods_id == item.goods_id;
              });
            });
          }
        }
      });
    },
    // 展示选择商品
    openGoods() {
      this.chooseGoodsShow = true;
      this.getGoodsList();
    },
    // 关闭
    closeChooseGoodsShow() {
      this.chooseGoodsShow = false;
      this.goodsSelection = [];
      this.goods_name = "";
    },
    // 获取商品列表
    getGoodsList() {
      let params = {
        goods_name: this.goods_name,
        pageNumber: 1000,
      };
      discounts.shopGoodsLists(params).then((res) => {
        let { code, data } = res;
        if (code === 0) {
          this.goodsData = data.list;
          // 过滤掉列表已经有的数据
          if (this.chooseGoodsData) {
            this.goodsData = this.goodsData.filter((item) => {
              return !this.chooseGoodsData.find((prop) => {
                return prop.goods_id == item.goods_id;
              });
            });
          }
        }
      });
    },
    confirmGoods() {
      this.chooseGoodsData = [...this.chooseGoodsData, ...this.goodsSelection];
      if (this.chooseGoodsData.length > 10) {
        this.chooseGoodsData = this.chooseGoodsData.slice(0, 10);
      }
      this.closeChooseGoodsShow();
    },
    // 选择商品
    handleSelectionChange(val) {
      console.log(123, val);
      this.goodsSelection = val;
    },
    // 删除当前商品
    delThisGoods(index) {
      this.chooseGoodsData.splice(index, 1);
    },
    //上传图片
    uploadImg(params, item) {
      let file = params.file;
      uploadImg(file)
        .then((res) => {
          if (params.action == "img_id_array" && !item) {
            this.form.img_id_array.push(res.data.path);
          } else if (params.action == "img_id_array" && item) {
            let index = this.form.img_id_array.findIndex((ele) => {
              return ele == item;
            });
            this.form.img_id_array[index] = res.data.path;
            this.$forceUpdate();
          } else if (params.action == "description" && !item) {
            this.form.description.push(res.data.path);
          } else if (params.action == "description" && item) {
            let index = this.form.description.findIndex((ele) => {
              return ele == item;
            });
            this.form.description[index] = res.data.path;
            this.$forceUpdate();
          } else if (params.action == "videoPic") {
            this.videoInfo.mianUrl = res.data;
          } else if (item.spec_value_id) {
            item.picture = res.data.path;
            this.setSkuList();
          } else if (item) {
            item = res.data.path;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //查看图片
    showImg(item) {
      this.imgList = [item];
      this.showViewer = true;
    },
    //删除图片
    delImg(item, type) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let list = [];
          switch (type) {
            case 1:
              list = this.form.img_id_array;
              list.splice(
                list.findIndex((ele) => item == ele),
                1
              );
              break;
            case 2:
              list = this.form.description;
              list.splice(
                list.findIndex((ele) => item == ele),
                1
              );
              break;
            case 3:
              this.videoInfo.mianUrl = "";
              break;

            default:
              break;
          }
        })
        .catch(() => {});
    },
    //从历史记录中选择图片
    chooseImg(imgData) {
      let length1 = this.form.img_id_array.length;
      let length2 = imgData.length;
      if (length1 + length2 > 5) {
        this.$message.error("选择后幻灯片图片总数量超过5个！");
        return;
      } else {
        let arr = imgData.map((ele) => {
          return ele.pic_url_str;
        });
        this.form.img_id_array = this.form.img_id_array.concat(arr);
        this.historyImgShow = false;
      }
    },
    //添加规格
    addSpec() {
      let length = this.form.spec_list.length;
      if (length >= 2) {
        this.$message.error("最多添加两种规格");
        return;
      }
      this.specShow = true;
    },
    //确认添加规格
    specConfirm() {
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
      });
      let check = false;
      this.$refs.specForm.validate((valid) => {
        check = valid;
      });

      if (!check) {
        return;
      }
      let obj = {
        spec_name: this.specForm.spec_name,
        spec_id: -new Date().getTime(),
        value: [
          {
            spec_value_name: "",
            spec_value_id: -new Date().getTime(),
            picture: "",
          },
        ],
      };
      this.form.spec_list.push(obj);
      this.specForm.spec_name = "";
      this.specShow = false;
      this.setSkuList();
    },
    //删除规格
    delSpec(type) {
      this.$confirm("确认要删除整个规格吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type == 1) {
            this.form.spec_list.splice(0, 1);
          } else {
            this.form.spec_list.splice(1, 1);
          }
          this.form.sku_list = [];
          this.setSkuList();
          this.$message.success("删除成功！");
        })
        .catch(() => {});
    },
    //添加规格子项
    addSpecChild(type) {
      let obj = {
        spec_value_name: "",
        spec_value_id: -new Date().getTime(),
        picture: "",
      };
      if (type == 1) {
        this.form.spec_list[0].value.push(obj);
        this.setSkuList();
      }
      if (type == 2) {
        this.form.spec_list[1].value.push(obj);
        this.setSkuList();
      }
    },
    //删除规格子项
    delSpecChild(type, id) {
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
      });
      this.$confirm("确认删除规格吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let list = [];
          if (type == 1) {
            list = this.form.spec_list[0].value;
            if (this.form.spec_list[0].value.length == 1) {
              this.$message.error("请至少填写一个规格值");
              return;
            }
          } else if (type == 2) {
            list = this.form.spec_list[1].value;
            if (this.form.spec_list[1].value.length == 1) {
              this.$message.error("请至少填写一个规格值");
              return;
            }
          }
          let index = list.findIndex((ele) => {
            return ele.spec_value_id == id;
          });
          list.splice(index, 1);
          this.setSkuList();
        })
        .catch(() => {});
    },
    //改变sku列表
    setSkuList() {
      let newValue = JSON.parse(JSON.stringify(this.form.spec_list));
      if (!newValue[0]) {
        return;
      }
      let arr1 =
        newValue[0].value.filter((ele) => {
          return ele.spec_value_name;
        }) || [];
      let arr2 = [];
      if (newValue[1]) {
        arr2 =
          newValue[1].value.filter((ele) => {
            return ele.spec_value_name;
          }) || [];
      }
      if (arr1.length == 0) {
        this.form.sku_list = [];
        return;
      }
      let sku_list = [];
      arr1.forEach((arr1_ele) => {
        if (arr2.length == 0) {
          let origin = this.form.sku_list.find((sku_ele) => {
            return sku_ele.sku_name == arr1_ele.spec_value_name;
          });
          if (origin) {
            //如果有已经填了的值 直接覆盖不新增
            origin.picture = arr1_ele.picture;
            origin.sku_1 = arr1_ele.spec_value_name;
            sku_list.push(origin);
          } else {
            let obj = {
              sku_name: arr1_ele.spec_value_name,
              sku_1: arr1_ele.spec_value_name,
              price: undefined,
              member_price: undefined,
              cost_price: undefined,
              stock: undefined,
              picture: arr1_ele.picture,
              goods_code: "",
              weight: undefined,
              key: Date.now(),
              remark: "",
            };
            sku_list.push(obj);
          }
        } else {
          arr2.forEach((arr2_ele) => {
            let origin = this.form.sku_list.find((sku_ele) => {
              return (
                sku_ele.sku_name ==
                arr1_ele.spec_value_name + " " + arr2_ele.spec_value_name
              );
            });
            if (origin) {
              //如果有已经填了的值 直接覆盖不新增
              origin.picture = arr1_ele.picture;
              origin.sku_1 = arr1_ele.spec_value_name;
              origin.sku_2 = arr2_ele.spec_value_name;
              sku_list.push(origin);
            } else {
              let obj = {
                sku_name:
                  arr1_ele.spec_value_name + " " + arr2_ele.spec_value_name,
                sku_1: arr1_ele.spec_value_name,
                sku_2: arr2_ele.spec_value_name,
                price: undefined,
                member_price: undefined,
                stock: undefined,
                cost_price: undefined,
                picture: arr1_ele.picture,
                goods_code: "",
                weight: undefined,
                sku_id: -new Date().getTime(),
                remark: "",
                key: Date.now(),
              };
              sku_list.push(obj);
            }
          });
        }
      });
      this.form.sku_list = sku_list;
    },
    inputlop() {
      this.setSkuList();
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.form.spec_list.length !== 2) {
        return;
      }
      if (columnIndex === 0) {
        const obj = this.form.spec_list[1].value
          .map((res) => {
            return res.spec_value_name;
          })
          .filter(function(s) {
            return s && s.trim();
          });
        if (obj.length) {
          if (rowIndex % obj.length === 0) {
            return {
              rowspan: obj.length,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        } else {
          // if (rowIndex % this.form.spec_list[1].value.length === 0) {
          //   return {
          //     rowspan: this.form.spec_list[1].value.length,
          //     colspan: 1,
          //   };
          // } else {
          //   return {
          //     rowspan: 0,
          //     colspan: 0,
          //   };
          // }
          return;
        }
      }
    },
    //获取运费模板列表
    getShippingFee() {
      this.axios
        .post("/store/Shopshipping/getShopShippingList", {
          page: 1,
          pageNumber: 100,
        })
        .then((res) => {
          this.feeList = res.data.list;
        });
    },
    //去新建运费模板
    toAddFreight() {
      commonUtil.lostFocus(event);
      let routeData = this.$router.resolve({
        path: "/system/addFreight",
      });
      window.open(routeData.href, "_blank");
    },

    //提交表单
    submit() {
      commonUtil.lostFocus(event);
      let check = false;
      this.$refs.ruleForm.validate((valid) => {
        check = valid;
      });
      if (!check) {
        return;
      }
      this.loading = true;
      const parameter_content = [
        ...this.form.parameter_content,
        ...this.form.parameter_content1,
      ];
      let data = {
        goods_id: this.goods_id,
        sort: this.form.sort || "",
        goods_name: this.form.goods_name,
        goods_code: this.form.goods_code,
        goods_type: this.form.goods_type,
        price: this.form.price,
        cost_price: this.form.cost_price,
        member_price: this.form.member_price,
        stock: this.form.stock,
        goods_weight: this.form.goods_weight,
        state: this.form.state,
        sales: this.form.sales,
        shipping_fee_type:
          this.form.psfs_id == "3"
            ? 3
            : this.form.psfs_id == "4"
            ? this.form.shipping_fee_type.toString()
            : "",
        shipping_fee: this.form.shipping_fee,
        shipping_fee_id: this.form.shipping_fee_id,
        open_spec: this.form.open_spec ? 1 : 0,
        is_show: this.form.is_show,
        is_service: this.form.is_service,
        integral_price: this.form.integral_price,
        is_share: this.form.is_share,
        parameter_content: JSON.stringify(parameter_content),
        mian_url: this.chooseVideo.mian_url,
        video_url: this.chooseVideo.video_url,
        recommend_goods_ids: this.chooseGoodsData.map((e) => {
          return e.goods_id;
        }),
      };
      // 旧的分类
      // data.category_id_1 = this.form.category[0];
      // data.category_id_2 = this.form.category[1];
      // 新的分类
      var arr = [];
      this.form.category.map((res) => {
        const obj = {
          category_id_1: res[0],
          category_id_2: res[1],
        };
        console.log(obj);
        arr.push(obj);
      });
      data.category_id_list = arr;
      let img_id_array = this.form.img_id_array.map((ele) => {
        return getImgUrl(ele);
      });
      data.img_id_array = img_id_array.toString();
      if (this.form.description.length > 0) {
        let description = this.form.description.map((ele) => {
          return getImgUrl(ele);
        });
        data.description = description.toString();
      }
      if (this.form.limit_time && this.form.limit_time.length > 0) {
        data.limit_time_start = this.dayjs(this.form.limit_time[0]).format(
          "YYYY-MM-DD HH:mm"
        );
        data.limit_time_end = this.dayjs(this.form.limit_time[1]).format(
          "YYYY-MM-DD HH:mm"
        );
      }
      if (this.form.spec_list.length > 0) {
        let spec_list = [];
        this.form.spec_list.forEach((ele1) => {
          let obj1 = {
            spec_name: ele1.spec_name,
            spec_id: ele1.spec_id > 0 ? ele1.spec_id : 0,
          };
          let value = [];
          ele1.value.forEach((ele2) => {
            if (ele2.spec_value_name) {
              value.push({
                spec_value_name: ele2.spec_value_name,
                spec_value_id: ele2.spec_value_id > 0 ? ele2.spec_value_id : 0,
                picture: getImgUrl(ele2.picture),
              });
            }
          });
          obj1.value = value;
          spec_list.push(obj1);
        });
        data.spec_list = spec_list;
      }
      if (this.form.sku_list.length > 0) {
        let sku_list = [];
        this.form.sku_list.forEach((ele) => {
          let obj = {
            sku_id: ele.sku_id > 0 ? ele.sku_id : 0,
            sku_name: ele.sku_name,
            price: ele.price,
            member_price: ele.member_price,
            cost_price: ele.cost_price,
            stock: ele.stock,
            picture: getImgUrl(ele.picture),
            goods_code: ele.goods_code,
            weight: ele.weight,
            remark: ele.remark,
          };
          sku_list.push(obj);
        });
        data.sku_list = sku_list;
      }
      console.log(data);
      this.axios
        .post("/store/Shopgoods/editGoods", data)
        .then(() => {
          this.$message.success("编辑成功");
          this.loading = false;
          this.$router.push("/goods/goodsList");
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    cancel() {
      commonUtil.lostFocus(event);
      this.$router.go(-1);
    },
    // 启动积分抵扣
    integralChange(val) {
      this.form.integral_price = val ? this.form.integral_price : 0;
    },
    // 删除商品参数
    delThisParameterContent(val) {
      this.form.parameter_content1.splice(val, 1);
    },
    // 增加商品参数
    addParameterContent() {
      const a = {
        name: "",
        value: "",
      };
      this.form.parameter_content1.push(a);
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getVideo();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getVideo();
    },
    // 获取历史视频列表
    checkVideo() {
      this.historyVideoShow = true;
      this.getVideo();
    },
    uploadVideo() {
      this.uploadVideoShow = true;
      this.videoInfo = {
        videoUrl: "",
        videoUrlStr: "",
        videoTitle: "",
        mianUrl: {},
        longTime: "",
      };
    },
    getVideo() {
      const data = {
        keywords: this.thekeywords,
        page: this.page,
        pageNumber: this.pageNumber,
      };
      this.axios
        .post("/store/Shopgoods/getGoodsVideoList", data)
        .then((res) => {
          this.videolist = res.data.list;
          this.videolist = res.data.list.map((item) => {
            item.check = false;
            return item;
          });
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    beforeUpload(file) {
      // 获取文件信息
      const fileSizeInMB = file.size / (1024 * 1024); // 转换为MB
      const maxSizeInMB = 30; // 你设定的最大允许大小，单位MB
      console.log("0000", file);
      // 验证文件大小
      if (fileSizeInMB > maxSizeInMB) {
        this.$message.error(`文件大小不能超过 ${maxSizeInMB}MB`);
        return false; // 阻止上传
      }
      // 通过视频预览获取时长
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.onloadedmetadata = () => {
        // 获取视频时长
        this.videoInfo.longTime = videoElement.duration;
        console.log("videoElement.duration", videoElement.duration);
        document.body.removeChild(videoElement);
      };
      // 设置 videoElement 的 src
      const objectURL = URL.createObjectURL(file);
      videoElement.src = objectURL;
      document.body.appendChild(videoElement); // 将 videoElement 添加到 DOM 中以触发 loadedmetadata 事件
      // 其他验证逻辑...

      // 返回 true 表示允许上传
      return true;
    },
    handleSuccess(response, file, fileList) {
      // 上传成功的回调
      console.log("上传成功", response);
    },
    customUpload(file) {
      // 自定义上传方法
      const formData = new FormData();
      // 添加其他需要上传的参数
      // 自定义上传方法
      formData.append("image", file.file);
      formData.append("img_type", 3);
      formData.append("suffix", "mp4");
      // 发起上传请求
      this.axios
        .post("/store/upload/uploadFile", formData)
        .then((response) => {
          // 处理上传成功的响应
          console.log(response.data);
          this.videoInfo.videoUrl = response.data.pic;
          this.videoInfo.videoUrlStr = response.data.path;
          this.getVideoCover(this.videoInfo.videoUrlStr);
        })
        .catch((error) => {
          // 处理上传失败的错误
          console.error(error);
        });
    },
    getVideoCover(fileUrl) {
      const video = document.createElement("video"); // 也可以自己创建video
      video.src = fileUrl; // url地址 url跟 视频流是一样的
      let canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧
      video.oncanplay = () => {
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        let _videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        this.uploadImgNow(_videoFirstimgsrc, ".png");
        video.remove();
        canvas.remove();
      };
    },
    // 上传封面
    uploadImgNow(base64, filename) {
      const file = this.dataURLtoFile(base64, filename);
      const formData = new FormData();
      formData.append("image", file);
      formData.append("img_type", 4);
      formData.append("suffix", ".png");
      this.axios.post("/store/upload/uploadFile", formData).then((res) => {
        let { code, data } = res;
        if (code === 0) {
          this.coverImg = data;
          console.log("123123", this.coverImg);
        }
      });
    },
    dataURLtoFile(base64, filename) {
      const arr = base64.split(",");
      const typeMatch = arr[0].match(/:(.*?);/);
      const mime = typeMatch[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    uploadVideoCerten() {
      let check = false;
      this.$refs.videoRuleForm.validate((valid) => {
        check = valid;
      });
      if (!check) {
        return;
      }
      const data = {
        video_title: this.videoInfo.videoTitle,
        video_url: this.videoInfo.videoUrl,
        mian_url: this.videoInfo.mianUrl.pic || this.coverImg.pic,
        long_time: this.videoInfo.longTime,
      };
      console.log("data", data);
      this.axios.post("/store/Shopgoods/saveGoodsVideo", data).then((res) => {
        this.uploadVideoShow = false;
        this.chooseVideo = {
          video_title: this.videoInfo.videoTitle,
          video_url: this.videoInfo.videoUrl,
          video_url_str: this.videoInfo.videoUrlStr,
          mian_url_str: this.videoInfo.mianUrl.path || this.coverImg.path,
          mian_url: this.videoInfo.mianUrl.pic || this.coverImg.pic,
          long_time: this.videoInfo.longTime,
        };
      });
    },
    formatTime(totalSeconds) {
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes}:${seconds}`;
    },
    //选中图片
    checkImg(index) {
      this.videolist.forEach((item, i) => {
        if (i !== index) {
          item.check = false;
        }
      });
      // 切换当前选项的 check 属性
      this.videolist[index].check = !this.videolist[index].check;
      this.$forceUpdate();
    },
    // 确定选择视频
    historyVideoConfirm() {
      let hasActiveElement = this.videolist.some((item) => item.check);
      if (!hasActiveElement) {
        this.$message.error("请选择视频");
        return;
      }
      this.historyVideoShow = false;
      this.chooseVideo = this.videolist.find((item) => item.check);
    },
    openPreview() {
      // 点击视频时打开预览模态框
      this.isPreviewVisible = true;

      // 监听全局点击事件，以便在点击模态框外部时关闭预览
      window.addEventListener("click", this.globalClickHandler);
    },
    closePreview() {
      // 关闭预览模态框
      this.isPreviewVisible = false;

      // 移除全局点击事件监听器
      window.removeEventListener("click", this.globalClickHandler);

      // 重置预览视频的播放状态
      this.$refs.previewPlayer.currentTime = 0;
      this.$refs.previewPlayer.pause();
    },
    globalClickHandler(event) {
      // 在全局点击事件处理程序中检查点击的区域是否在预览模态框之外
      const previewModal = this.$refs.previewModal;
      if (previewModal && !previewModal.contains(event.target)) {
        this.closePreview();
      }
    },
    deleteVideo() {
      this.chooseVideo = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.new_box {
  width: 100%;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 4px;
  padding: 0 20px 10px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .gg_name_box {
    .gg_name_item {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      background: #f2f2f2;
      margin: 10px 0;
      .gg_name {
      }
    }
  }
}
.new_table {
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.sku-box-detail {
  display: flex;
  align-items: center;
}
.lineH-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #606266;
  font-weight: 700;
  margin-bottom: 20px;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.empty-box {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e4;
}
.el-input,
.el-input-number,
.el-cascader {
  width: 250px;
  margin-right: 5px;
}
.imgList {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 100px;
  .box {
    position: relative;
    &:hover .shadow {
      display: flex;
    }
    .shadow {
      position: absolute;
      left: 0;
      top: 0;
      width: 150px;
      height: 150px;
      background-color: rgba(0, 0, 0, 0.6);
      display: none;
    }
    i + i {
      margin-left: 30px;
      color: #d44747;
    }
    i {
      color: #ffffff;
      font-size: 30px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.spec_title {
  margin-bottom: 10px;
  .name {
    margin-right: 10px;
    font-size: 14px;
  }
  span {
    margin-left: 10px;
  }
}
.spec_add {
  color: #4974f5;
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  i {
    margin-right: 5px;
  }
}
.spec_box {
  width: 310px;
  margin-right: 50px;

  i {
    font-size: 25px;
    // margin-top: -20px;
    cursor: pointer;
    color: #aaaaaa;
  }
  i + div {
    // margin-top: -10px;
    // margin-left: 5px;
    img {
      width: 25px;
      height: 25px;
      // margin-top: -10px;
    }
  }
}
.sku_box {
  margin: 15px 0;
  .title {
    font-weight: bold;
    width: 100px;
  }
  .el-input,
  .el-input-number,
  .el-cascader {
    width: 100px;
    margin-right: 5px;
  }
}
.submit {
  margin: 30px 0;
}
.chooseVideoBox {
  position: relative;
  width: 150px;
  height: 120px;
  .closed {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    z-index: 99;
  }
}
.videoBox {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .videoItem {
    width: 20%;
    height: 100px;
    margin-right: 5%;
    margin-top: 20px;
    position: relative;
    .checkimg {
      position: absolute;
      width: 60px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .timeBox {
      background: rgba(0, 0, 0, 0.3);
      color: #ffffff;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25px;
      font-size: 14px;
      line-height: 25px;
      padding-left: 5px;
      box-sizing: border-box;
    }
    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px 0;
    }
  }
}
.imgbox {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  .img {
    width: auto;
    height: 110px;
    margin-right: 10px;
    margin-bottom: 8px;
  }
}
.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-modal video {
  max-width: 100%;
  max-height: 100%;
}
.recommendBox {
  margin-right: 10px;
  width: 150px;
  .itemBox {
    width: 150px;
    height: 150px;
    position: relative;
    img {
      width: 150px;
      height: 150px;
    }
    .el-icon-error {
      color: #ffffff;
      font-size: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
  }
}
</style>
<style lang="scss">
.addGood {
  .imgList .el-image {
    img {
      max-height: 150px;
      width: auto;
    }
  }
}
.el-image-viewer__wrapper {
  z-index: 999999 !important;
}
</style>
