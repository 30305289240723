import { render, staticRenderFns } from "./EditGood.vue?vue&type=template&id=8dc1c72a&scoped=true&"
import script from "./EditGood.vue?vue&type=script&lang=js&"
export * from "./EditGood.vue?vue&type=script&lang=js&"
import style0 from "./EditGood.vue?vue&type=style&index=0&id=8dc1c72a&lang=scss&scoped=true&"
import style1 from "./EditGood.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dc1c72a",
  null
  
)

export default component.exports